import React from "react";

interface IslandCardAboutProps {
  imgPath?: string;
  islandName?: string;
  description?: string;
  listSubject?: string[];
  colorBgSubject?: string;
}

const IslandCardAbout: React.FC<IslandCardAboutProps> = ({
  imgPath,
  islandName,
  description,
  listSubject = [],
  colorBgSubject,
}) => {
  return (
    <div className="bg-white shadow-lg rounded-[30px] h-[638px] pv:max-pvmax:h-[300px] pvmax:max-ph:h-[340px] md:max-lg:h-[450px] lg:max-xl:h-[600px] dv:max-dk:h-[650px] dhmin:max-dh:h-[680px] text-[#142548]">
      {/* Image Section */}
      <img
        src={imgPath}
        alt={`Đảo ${islandName}`}
        className="rounded-t-[30px] w-full h-[328px] pv:max-pvmax:h-[170px] pvmax:max-ph:h-[220px] md:max-lg:h-[250px] lg:max-xl:h-[300px] dhmin:max-dh:h-[380px]"
      />
      {/* Content Section */}
      <div className="p-4 pv:max-md:p-2">
        <div className=" text-center">
          <h2 className="text-[30px] pv:max-md:text-[10px] md:max-lg:text-[12px] lg:max-xl:text-[18px] font-[800] dv:max-dk:text-[25px] text-gray-800 mb-2">
            ĐẢO {islandName?.toUpperCase()}
          </h2>
          <p className="mb-4 text-[20px] dhmin:max-dh:text-[25px] pv:max-md:text-[7px]  pv:max-md:leading-[7px] md:max-lg:text-[10px] lg:max-xl:text-[16px] dv:max-dk:text-[18px] md:max-lg:leading-[10px] leading-[20px] text-justify">
            {description}
          </p>
        </div>

        {/* Button Section */}
        <div className="flex flex-wrap gap-2 pv:max-md:gap-1 justify-center">
          {listSubject.map((subject, index) => (
            <button
              className={`px-4 py-2 rounded-full text-[14px] pv:max-md:text-[5px] md:max-lg:text-[8px] lg:max-xl:text-[10px] dv:max-dk:text-[12px] pv:max-md:px-[4px] pv:max-md:py-[4px] md:max-dk:px-[8px] md:max-dk:py-[4px] font-semibold`}
              style={{ backgroundColor: colorBgSubject }}
            >
              {subject}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IslandCardAbout;
