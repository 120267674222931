import React from "react";
import { ServicePackageInfo } from "../service_package_component/servicePackageTypes";

interface PackageRadioButtonProps {
  selectedValue: string | number;
  onChange: (
    value: number,
    label: string,
    price: number,
    title: string,
    ID: string
  ) => void;
  servicePackageData: ServicePackageInfo[]; // Add this line
}

const PackageRadioButton2: React.FC<PackageRadioButtonProps> = ({
  selectedValue,
  onChange,
  servicePackageData, // Destructure here
}) => {
  const handleChange = (priority: number) => {
    const selectedPackage = servicePackageData.find(
      (pkg) => pkg.priority === priority
    );
    if (selectedPackage) {
      onChange(
        selectedPackage.priority,
        selectedPackage.title,
        selectedPackage.price,
        selectedPackage.title,
        selectedPackage.ID
      );
      // console.log("Selected Package Data:", selectedPackage);
    }
  };

  return (
    <div className="w-full">
      {servicePackageData.map((option) => (
        <label
          key={option.ID}
          className="flex justify-between items-center border-2 border-[#142548] text-[#142548] w-full px-10 py-3 pv:max-md:py-1 my-2 pv:max-md:px-4 pv:max-sm:rounded-[25px] rounded-[20px] cursor-pointer"
        >
          <div className="flex items-center space-x-4 pv:max-md:space-x-0">
            <div className="relative">
              <input
                type="radio"
                value={option.priority}
                checked={selectedValue === option.ID}
                onChange={() => handleChange(option.priority)}
                className="absolute opacity-0 h-6 w-6 pv:max-md:h-3 pv:max-md:w-3"
                aria-labelledby={`option-${option.ID}`} // Accessibility improvement
              />
              <div
                className={`h-6 w-6 pv:max-md:h-3 pv:max-md:w-3 rounded-full border-2 flex justify-center items-center transition-all duration-300 ease-in-out ${
                  selectedValue === option.priority
                    ? "border-[#142548] bg-[#142548] scale-110"
                    : "border-[#142548]"
                }`}
              >
                <div
                  className={`transition-all duration-300 ease-in-out ${
                    selectedValue === option.priority
                      ? "h-3 w-3 pv:max-md:h-1 pv:max-md:w-1 bg-white rounded-full"
                      : "h-6 w-6 pv:max-md:h-3 pv:max-md:w-3 bg-[#142548] rounded-full"
                  }`}
                ></div>
              </div>
            </div>
            <p
              id={`option-${option.ID}`}
              className="text-[30px] pv:max-md:text-[12px] font-[600] flex justify-center items-center gap-3"
            >
              <img
                className="w-10 pv:max-sm:h-[25px] md:h-[30px] object-contain"
                src={`character_service_package/${option.img}.png`}
              />
              <span>{option.title}</span>
            </p>
          </div>
          <div className="flex flex-col items-end justify-end">
            {/* {option.orignalPrice && (
              <p className="font-medium text-[22px] pv:max-md:text-[10px] line-through">
                {option.orignalPrice.toLocaleString("de-DE")}đ
              </p>
            )} */}
            <p className="font-bold text-[33px] pv:max-md:text-[12px]">
              {option.price.toLocaleString("de-DE")}đ
            </p>
          </div>
        </label>
      ))}
    </div>
  );
};

export default PackageRadioButton2;
