import { createBrowserRouter } from "react-router-dom";
import HomePage from "../../pages/home";
import TempPage from "../../pages/temp";
import PolicyPage from "../../pages/term_policy/policy";
import LearningLibrary from "../../pages/learningLibrary";
import LearningLibraryDetail from "../../pages/learningLibraryDetail";
import PrivateRoute from "../../pages/PrivatePage";
import TermVNPage from "../../pages/Term/term_vn";
import TermENPage from "../../pages/Term/term_en";
import PolicyVNPage from "../../pages/Policy/policy_vn";
import PolicyEnPage from "../../pages/Policy/policy_en";
import LearningLibraryIntroductionPage from "../../pages/LearningLibraryIntroductionPage";
import BlogAndNews from "../../pages/BlogAndNews";
import LoginUserRoute from "../../pages/PrivatePage/index_user";
import PaymentHandler from "../../pages/CheckOutOrder/paymentHandle";
import BlogAndNewsDetail from "../../pages/BlogAndNewsDetail";
import PaymentInstructions from "../../pages/PaymentInstruction";
import PaymentProcess from "../../pages/CheckOutOrder/paymentProcess";
import ServicePackage from "../../pages/servicePackage";
import AccountPage from "../../pages/Account/AccountPage";
import TermPage from "../../pages/term_policy/term";
import PolicyRefund from "../../pages/PolicyRefund";
import PolicyPaymentPrivate from "../../pages/PolicyPaymentPrivate";
import CheckOutOrder from "../../pages/CheckOutOrder";
import AboutWhyKids from "../../pages/AboutWhyKids";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/policy-refund",
    element: <PolicyRefund />,
  },
  {
    path: "/policy-payment-private",
    element: <PolicyPaymentPrivate />,
  },
  {
    path: "/temp",
    element: <TempPage />,
  },
  {
    path: "/about-WhyKids",
    element: <AboutWhyKids />,
  },
  {
    path: "/login",
    element: <LoginUserRoute />,
  },
  {
    path: "/tin-tuc",
    element: <BlogAndNews />,
  },
  {
    path: "/tin-tuc/:detailId",
    element: <BlogAndNewsDetail />,
  },
  {
    path: "/account",
    element: <AccountPage />,
  },
  {
    path: "/checkout-order/paymentprocess",
    element: <PaymentHandler />,
  },
  {
    path: "/paymentprocess",
    element: <PaymentProcess />,
  },
  {
    path: "/payment-instruction",
    element: <PaymentInstructions />,
  },
  {
    path: "/library",
    element: <PrivateRoute component={LearningLibrary} />,
  },
  {
    path: "/library/detail/:islandName",
    element: <PrivateRoute component={LearningLibraryDetail} />,
  },
  {
    path: "/service-package",
    element: <ServicePackage />,
  },
  {
    path: "/checkout-order",
    element: <CheckOutOrder />,
  },
  // term policy old version
  {
    path: "/term",
    element: <TermPage />,
  },
  {
    path: "/policy",
    element: <PolicyPage />,
  },
  {
    path: "/term-en",
    element: <TermPage />,
  },
  {
    path: "/policy-en",
    element: <PolicyPage />,
  },
  // term policy new version
  {
    path: "/term-2",
    element: <TermVNPage />,
  },
  {
    path: "/policy-2",
    element: <PolicyVNPage />,
  },
  {
    path: "/term-en-2",
    element: <TermENPage />,
  },
  {
    path: "/policy-en-2",
    element: <PolicyEnPage />,
  },
  {
    path: "/library-introduction",
    element: <LearningLibraryIntroductionPage />,
  },
]);

export { appRouter };
