import React, { useState } from "react";
import classNames from "classnames";
import { Divider } from "antd";

interface ServiceCardProps {
  packageType: string;
  packageColor: string;
  price: number;
  imageUrl: string;
  originPrice: number;
  pricePerMonth: number;
  isExpanded: boolean;
  on1stClick?: () => void;
  on2ndClick?: () => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  packageType,
  packageColor,
  imageUrl,
  price,
  originPrice,
  pricePerMonth,
  isExpanded,
  on1stClick,
  on2ndClick,
}) => {
  const [clickCount, setClickCount] = useState(0);

  const handleClick = () => {
    if (clickCount === 0 && on1stClick) {
      on1stClick();
      setClickCount(1);
    } else if (clickCount === 1 && on2ndClick) {
      on2ndClick();
      setClickCount(0); // Reset after the second click
    }
  };

  const formattedPrice = price.toLocaleString("de-DE");
  const formattedOriginPrice = originPrice.toLocaleString("de-DE");
  const formattedPricePerMonth = pricePerMonth.toLocaleString("de-DE");

  const renderFeature = (text: string) => (
    <div className="flex items-center gap-2">
      <img
        className="h-[34px] lg:max-xl:h-[20px] dhmin:max-dh:h-[42px] object-contain"
        src="/logo/icon_service_package.png"
        alt=""
      />
      <p className="text-white text-[16px] lg:max-xl:text-[14px] dhmin:max-dh:text-[20px] font-bold">
        {text}
      </p>
    </div>
  );

  return (
    <div onClick={handleClick}>
      <div
        className={classNames(
          "flex flex-col justify-between rounded-[30px] lg:max-xl:rounded-[20px] py-4 w-60 lg:max-xl:w-[200px] xl:max-dv:w-[220px] md:max-lg:w-[300px] pv:max-ph:w-[250px] dv:max-dvmax:w-[260px] dvmax:max-dhmin:w-[300px] dhmin:max-dh:w-[380px] shadow-lg transition-height duration-500 ease-in-out cursor-pointer relative",
          {
            "h-auto dhmin:max-dh:h-[640px] sm:max-lg:h-auto lg:max-xl:h-[420px] xl:max-dv:h-[520px] dv:max-dvmax:h-[500px] dvmax:max-dhmin:h-[520px] ":
              isExpanded,
            "h-auto dhmin:max-dh:h-[500px] sm:max-lg:h-[400px] lg:max-xl:h-[270px] xl:max-dv:h-[370px] dv:max-dvmax:h-[360px] dvmax:max-dhmin:h-[400px] ":
              !isExpanded,
          }
        )}
        style={{
          backgroundColor: packageColor,
          boxShadow: "0px 4.62px 10.28px 5.78px rgba(0, 82, 117, 0.25)",
        }}
        aria-expanded={isExpanded}
      >
        <div>
          <p className="font-bold dhmin:max-dh:text-[30px] lg:max-xl:text-[20px] dv:max-dvmax:text-[25px] text-[20px] text-center dvmax:max-dhmin:text-[27px] text-white mb-2 uppercase">
            {packageType}
          </p>
          <div
            className="bg-white min-w-fit max-w-fit pr-5 pl-4 rounded-e-[90px]"
            style={{
              boxShadow: "0px 4.62px 4.62px 0px rgba(0, 82, 117, 0.25)",
            }}
          >
            <div
              className="font-bold dhmin:max-dh:text-[70px] lg:max-xl:text-[35px] pv:max-ph:text-[45px] ph:max-md:text-[50px] md:max-lg:text-[60px] lg:max-dvmax:text-[45px] text-[56px] mb-[-20px]"
              style={{
                color: packageColor,
              }}
            >
              {formattedPrice}
            </div>
          </div>

          <div className="flex justify-center mt-10">
            <img
              src={`character_service_package/${imageUrl}.png`}
              alt={`service_package_${imageUrl}`}
              className="h-32 xl:max-dv:h-[140px] lg:max-xl:h-[90px] dhmin:max-dh:h-[220px] dvmax:max-dhmin:h-[170px] object-contain"
            />
          </div>
        </div>
        {pricePerMonth === 0 && !isExpanded && (
          <img
            className="w-[190px] lg:max-xl:w-[100px] xl:max-dv:w-[110px] dhmin:max-dh:w-[220px] pv:max-md:w-[140px] md:max-lg:w-[150px] absolute -bottom-1 -right-1 object-contain"
            src="/logo/longlife_badge.png"
            alt=""
          />
        )}

        {!isExpanded && pricePerMonth > 0 ? (
          <div className="flex gap-1 justify-center mt-2 items-end">
            <p className="font-bold dhmin:max-dh:text-[25px] lg:max-xl:text-[14px] text-xl dvmax:max-dhmin:text-[20px] text-white">
              ~{formattedPricePerMonth}
            </p>
            <p className="font-bold text-xl dvmax:max-dhmin:text-[20px] lg:max-xl:text-[14px] text-white">
              /
            </p>
            <p className="font-medium text-lg dhmin:max-dh:text-[20px] lg:max-xl:text-[14px] dvmax:max-dhmin:text-[17px] text-white">
              1 tháng
            </p>
          </div>
        ) : (
          <div className="h-[60px]" />
        )}

        {isExpanded && (
          <div className="flex-col items-center gap-10 justify-center px-10 lg:max-dv:px-6">
            {renderFeature("Cập nhật thường xuyên")}
            {renderFeature("An toàn không quảng cáo")}
            {renderFeature("Trải nghiệm không giới hạn bài học tương tác")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
