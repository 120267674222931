import { title } from "process";
import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";

const isAuthenticated = (): boolean => {
  // Kiểm tra trạng thái đăng nhập của người dùng
  // Có thể là kiểm tra token trong localStorage, session, v.v.
  return !!sessionStorage.getItem("accessTokenSchool");
};

const headerInfo = [
  { title: "Trang chủ", path: "/" },
  { title: "Về chúng tôi", path: "/" },
  { title: "Về Why Kids", path: "/about-WhyKids" },
  { title: "Gói dịch vụ", path: "/service-package" },
  {
    title: "Thư viện học liệu",
    path: isAuthenticated() ? "/library" : "/library-introduction",
  },
  { title: "Blog/ Sự kiện", path: "/tin-tuc" },
  { title: "Hỗ trợ", path: "/" },
];

function HeaderMobile() {
  const [isMenuVisible, setMenuVisible] = useState(false);

  // Function to toggle the menu visibility
  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible); // Toggles the state between true and false
  };

  return (
    <div className="py-10 flex justify-center relative">
      <div className="w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] flex justify-between bg-white py-5 pv:max-md:py-3 md:max-dv:py-3 pv:max-md:px-2 px-10 pv:max-ph:px-6 rounded-[40px]">
        <img
          src="/logo/menu_logo.png"
          className="h-12 pv:max-ph:h-6 object-contain"
          alt="menu logo"
          onClick={toggleMenu} // Toggle the menu on click
        />
        <a href="/" className="flex items-center">
          <img
            src="/logo/logo_header.png"
            className="h-14 pv:max-ph:h-7 object-contain"
            alt="Why Kids Logo"
          />
        </a>
        <a href="/login" className="flex items-center">
          <img
            src="/logo/account.png"
            className="h-12 pv:max-ph:h-6 object-contain"
            alt="Account Logo"
          />
        </a>
      </div>

      {/* Conditionally render the menu based on the state */}
      {isMenuVisible && (
        <div className="  absolute w-[85%] lg:max-xl:w-[92%] top-[120px] pv:max-ph:top-[55px] ph:max-lg:top-[90px] ">
          <div className="dvmax:dh:w-[90%] top-[140px] bg-white rounded-[30px] py-6 px-10 pv:max-md:px-4 mt-3 md:max-lg:mt-10 pv:max-md:mt-[50px]">
            <ul className="gap-1 font-bold text-lg pv:max-md:text-[18px] text-[#142548]">
              {headerInfo.map((info, index) => (
                <li className="p-1">
                  <a
                    href={info.path}
                    className="flex items-center justify-start"
                  >
                    <p className="normal-case">{info.title}</p>
                    <MdArrowForwardIos className="ml-6" />
                  </a>
                </li>
              ))}
              <button
                onClick={() => {
                  window.open("https://whycorp.vn/sugiatrithuc");
                }}
                className="px-4 py-1 rounded-[30px] text-white bg-[#F56177]"
              >
                Trở thành Sứ giả tri thức
              </button>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default HeaderMobile;
