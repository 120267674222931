import React from "react";
import { Carousel } from "antd";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";

const CustomNextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="pv:max-md:hidden z-50 absolute top-[50%] left-5 text-[70px] dvmax:max-dh:w-[40px]"
    >
      <img className="w-full h-full" src="logo/arrow_icon.png" alt="" />
    </div>
  );
};

const CustomPrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="pv:max-md:hidden z-50 rotate-180 absolute top-[50%] right-5 text-[70px] dvmax:max-dh:w-[40px]"
    >
      <img className="w-full h-full" src="logo/arrow_icon.png" alt="" />
    </div>
  );
};
// const PrevArrowIcon = ({ width = 50, height = 50, color = "whitesmoke" }) => {
//   return (
//     <div
//       style={{
//         width: width || "70px",
//         height: height || "70px",
//         borderRadius: "50%",
//         backgroundColor: "#f0f0f0", // Màu nền cho hình tròn, bạn có thể thay đổi tùy ý
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         transform: "rotate(0deg)", // Xoay icon nếu cần, ở đây là 0
//       }}
//     >
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 24 24"
//         fill={color}
//         width={width ? width / 2 : "35px"} // Điều chỉnh kích thước icon
//         height={height ? height / 2 : "35px"} // Điều chỉnh kích thước icon
//       >
//         <path d="M8.293 16.293a1 1 0 0 1 0-1.414L12.586 11 8.293 6.707a1 1 0 0 1 1.414-1.414l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0z" />
//       </svg>
//     </div>
//   );
// };
const imageList = [
  "img_slider/img1.png",
  "img_slider/img2.png",
  "img_slider/img3.png",
  "img_slider/img4.png",
  "img_slider/img5.png",
  "img_slider/img6.png",
  "img_slider/img7.jpg",
  "img_slider/img8.jpg",
];
interface BodyCarouselProps {
  reverseRow: boolean;
}
function BodyCarousel({ reverseRow = false }: BodyCarouselProps) {
  return (
    <div
      className={`w-full px-32 py-16 pv:max-md:px-5 pv:max-md:py-10 justify-between flex ${
        reverseRow ? "flex-row" : "flex-row-reverse"
      } pv:max-md:flex-col-reverse pv:max-md:items-center`}
    >
      <div className="w-[65%] md:max-xl:h-[30vh] dvmax:max-dhmin:h-[55vh] dhmin:max-dh:h-[60vh] pv:max-md:w-full place-content-end">
        <Carousel
          autoplay
          dotPosition="bottom"
          speed={100}
          lazyLoad="progressive"
          effect="fade"
          nextArrow={<CustomNextArrow />}
          prevArrow={<CustomPrevArrow />}
          fade={true}
          dots={false}
          arrows={true}
          className="w-full h-full"
        >
          {imageList.map((imageSrc, index) => (
            <img
              src={imageSrc}
              alt={`Slide ${index + 1}`}
              className="w-full md:max-xl:h-[30vh] dvmax:max-dhmin:h-[55vh] dhmin:max-dh:h-[60vh] object-cover rounded-[30px]"
            />
          ))}
        </Carousel>
      </div>
      {/* <div className="flex flex-col justify-end w-[45%] pv:max-md:w-full pv:max-md:text-center"> */}
      <div className="flex flex-col justify-end w-[30%] pv:max-md:w-full pv:max-md:text-center">
        <p className="uppercase font-[800] text-center tracking-wide text-[#142548] sm:max-md:text-3xl pv:max-md:text-[26px] md:max-lg:text-[40px] lg:max-xl:text-[35px] xl:max-dvmax:text-[50px] dvmax:max-dh:text-[70px]">
          {/* {title} */}
          Thuần Việt
        </p>
        <p className="font-[500] text-[#142548] text-[20px] text-justify dvmax:max-dh:text-[25px] lg:max-xl:text-[16px] xl:max-dvmax:text-[20px] sm:max-md:text-xl md:max-lg:text-[14px]">
          Why Kids giúp trẻ tập trung phát triển ngôn ngữ tiếng Việt, hiểu biết
          sâu sắc về văn hóa Việt Nam, cùng với hình ảnh đồ họa đậm chất Việt,
          tạo nên một trải nghiệm học tập thuần Việt độc đáo.
        </p>
      </div>
    </div>
  );
}

export default BodyCarousel;
