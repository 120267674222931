import React, {
  useEffect,
  useState,
} from "react";
import Header from "../../components/Common/header/header";
import Footer from "../../components/Common/footer";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { EffectCoverflow, Navigation, FreeMode } from "swiper/modules";
import IslandCardAbout from "../../components/Common/aboutWhykids";
// import BackgroundImage from "../../assets/images/about_whykids/background.png";

export const screenSize = {
  pv: "280px",
  pvmax: "360px",
  ph: "480px",
  sm: "640px",
  md: "768px",
  mdmax: "914px",
  lg: "1024px",
  xl: "1280px",
  xlmax: "1380px",
  dv: "1440px",
  dvmax: "1700px",
  dk: "1894px",
  dhmin: "2560px",
  dh: "5000px",
};

function getScreenRangeValue(screenSize: any) {
  // Chuyển đổi kích thước màn hình từ pixel (nếu là chuỗi) sang số
  const numericSize =
    typeof screenSize === "string"
      ? parseInt(screenSize.replace("px", ""), 10)
      : screenSize;
  // Kiểm tra khoảng giá trị và trả về kết quả
  if (numericSize >= 280 && numericSize <= 360) {
    return 1;
  } else if (numericSize > 360 && numericSize <= 480) {
    return 1;
  } else if (numericSize > 480 && numericSize <= 768) {
    return 2;
  } else if (numericSize > 768 && numericSize <= 1024) {
    return 2;
  } else if (numericSize > 1024 && numericSize <= 1280) {
    return 2;
  } else if (numericSize > 1280 && numericSize <= 1440) {
    return 2;
  } else if (numericSize > 1440 && numericSize <= 1700) {
    return 3;
  } else if (numericSize > 1700) {
    return 3;
  } else {
    return 3; // Không thuộc bất kỳ khoảng nào
  }
}

const AboutWhyKids: React.FC = () => {
  //   const swiper = useSwiper();
  //   const swiperRef = useRef<any>(null);
  const [slidesPerView, setSlidesPerView] = useState<number>(1);

  useEffect(() => {
    const updateSlidesPerView = () => {
      const screenWidth = window.innerWidth; // Lấy kích thước màn hình hiện tại
      setSlidesPerView(getScreenRangeValue(screenWidth)); // Tính toán slidesPerView
    };

    updateSlidesPerView(); // Cập nhật ngay khi component render lần đầu
    window.addEventListener("resize", updateSlidesPerView); // Lắng nghe sự kiện resize

    return () => {
      window.removeEventListener("resize", updateSlidesPerView); // Hủy sự kiện khi component unmount
    };
  }, []);

  const backgroundImageUrl =
    process.env.PUBLIC_URL + "/about_whykids/background.png";

  const islandsData = [
    {
      imgPath: "/about_whykids/card/tiengviet.png",
      islandName: "Tiếng Việt",
      description:
        "Tại Đảo Tiếng Việt, Why Kids trang bị cho trẻ khả năng nhận diện chữ cái, rèn luyện kỹ năng viết và phát âm chuẩn. Với những kiến thức này, trẻ không chỉ có thể giao tiếp tốt hơn mà còn tự tin bước vào hành trình học tập trong tương lai.",
      listSubject: [
        "Nhận diện chữ cái",
        "Nhận diện dấu",
        "Nhận diện vần",
        "Nhận diện phụ âm ghép",
        "Nhận biết cách tạo tiếng",
      ],
      bgColorSubject: "#FFBC42",
    },
    {
      imgPath: "/about_whykids/card/toan.png",
      islandName: "Toán",
      description:
        "Tại Đảo Toán, Why Kids mở ra cho trẻ một thế giới sắc màu với các con số và phép toán thú vị, giúp phát triển tư duy và khả năng quan sát. Từ đó trang bị cho trẻ những kỹ năng cần thiết, tạo nền tảng vững chắc cho hành trình học tập sau này.",
      listSubject: [
        "Nhận biết số",
        "Tập đếm số",
        "Phép tính cơ bản",
        "So sánh",
        "Nhận biết ngày, giờ",
        "Định hướng không gian",
      ],
      bgColorSubject: "#65AFFF",
    },
    {
      imgPath: "/about_whykids/card/vhdg.png",
      islandName: "Văn hoá dân gian",
      description:
        "Tại Đảo Văn Hóa, Why Kids dẫn dắt trẻ khám phá những giá trị văn hóa độc đáo của Việt Nam. Trẻ sẽ không chỉ hiểu biết về quê hương mà còn hình thành lòng tự hào và gắn kết với nguồn cội ngay từ những năm tháng đầu đời.",
      listSubject: [
        "Lễ hội truyền thống văn hóa Việt Nam",
        "Trò chơi dân gian",
        "Ngày lễ và sự kiện đặc biệt",
      ],
      bgColorSubject: "#9FD356",
    },
    {
      imgPath: "/about_whykids/card/knxh.png",
      islandName: " KĨ NĂNG XÃ HỘI",
      description:
        "Tại Đảo Kỹ Năng, Why Kids trang bị cho trẻ những kỹ năng thiết yếu để tự tin và vững vàng trong cuộc sống. Trẻ sẽ được chuẩn bị tốt hơn cho những tình huống hàng ngày, giúp xây dựng nền tảng vững chắc cho tương lai.",
      listSubject: [
        "Kỹ năng an toàn",
        "Kỹ năng giao tiếp, ứng xử",
        "Kiến thức đời sống xã hội",
        "Kỹ năng tự lập",
      ],
      bgColorSubject: "#007DA9",
    },
    {
      imgPath: "/about_whykids/card/khoahoc.png",
      islandName: "Khoa Học",
      description:
        "Tại Đảo Khoa Học, trẻ sẽ học thông qua các thí nghiệm STEM thú vị, khơi dậy sự tò mò và khám phá. Why Kids mang đến câu trả lời cho hàng triệu câu hỏi “tại sao” của trẻ về kỹ thuật, tự nhiên và sức khỏe, giúp các em hiểu biết về thế giới xung quanh.",
      listSubject: [
        "Kỹ thuật",
        "Thí nghiệm",
        "Hiện tượng tự nhiên",
        "Địa lý",
        "Động, thực vật",
        "Sức khỏe, thân thể",
      ],
      bgColorSubject: "#F16378",
    },
    {
      imgPath: "/about_whykids/card/nghethuat.png",
      islandName: "NGHỆ THUẬT",
      description:
        "Tại Đảo Nghệ Thuật, Why Kids khơi dậy sự sáng tạo và tư duy hình tượng của trẻ thông qua các hoạt động nghệ thuật, giúp trẻ tự do thể hiện bản thân và nuôi dưỡng tâm hồn, từ đó thúc đẩy sự phát triển toàn diện.",
      listSubject: [
        "Họa cụ",
        "Chất liệu hội họa",
        "Nhận biết màu sắc",
        "Các định nghĩa trong âm nhạc",
        "Nghệ thuật biểu diễn",
      ],
      bgColorSubject: "#8DD4EA",
    },
  ];

  const figureCardData = [
    "/about_whykids/figure_card/bu.png",
    "/about_whykids/figure_card/dodo.png",
    "/about_whykids/figure_card/mat.png",
    "/about_whykids/figure_card/may.png",
    "/about_whykids/figure_card/tkay.png",
  ];

  return (
    <div className="bg-white w-full">
      <div className="bg-[#142548]">
        <Header />
        <div className="flex justify-center">
          <div className="w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] my-16 h-[648px] pv:max-md:h-auto md:max-lg:h-[500px] pv:max-md:my-4 flex justify-between pv:max-md:flex-col pv:max-md:w-full items-end">
            <div className="w-[50%] pv:max-md:w-full pv:max-md:px-5 h-full">
              <img
                src="/about_whykids/about_whykids_banner.jpeg"
                className="h-full w-full rounded-[30px] object-cover"
                alt=""
              />
            </div>
            <div className="w-[43%] pv:max-md:w-full pv:max-md:px-5">
              <div className="font-[800] uppercase text-[70px] pv:max-md:text-[30px] md:max-lg:text-[50px] lg:max-xl:text-[60px] pv:max-md:text-nowrap pv:max-md:text-center text-white flex gap-4">
                <p>
                  Giới thiệu <span className="text-[#F56177]">Why Kids</span>{" "}
                </p>
                {/* <span className="text-[#F56177]">Why Kids</span> */}
              </div>
              <div className="text-white font-[500] text-[25px] pv:max-md:text-[14px] pv:max-md:leading-[14px] md:max-lg:text-[16px] md:max-lg:leading-[15px] lg:max-xl:text-[20px] text-justify leading-[26px]">
                <p>
                  Why Kids là ứng dụng giáo dục sớm dành cho trẻ em 3-7 tuổi,
                  mang đến trải nghiệm học tập hiện đại và toàn diện trong thời
                  đại chuyển đổi số. Ứng dụng cung cấp các bài học và hoạt động
                  sáng tạo về Toán, Tiếng Việt, Kỹ năng sống, Văn hóa - Dân
                  gian, Khoa học và Nghệ thuật, giúp trẻ phát triển kỹ năng mềm
                  và kiến thức học tập toàn diện.
                </p>
                <p className="mt-4">
                  Why Kids không chỉ là công cụ học tập, mà còn là phương thức
                  kết nối giữa giáo viên và học sinh, phụ huynh và con cái, tạo
                  ra môi trường học tập số hóa nơi các bé có thể trải nghiệm và
                  khám phá kiến thức một cách tự nhiên, hứng thú.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="font-[800] text-[70px] pv:max-md:text-[30px] md:max-lg:text-[50px] pv:max-md:px-5 uppercase text-center my-16">
        <h1 className="text-[#9FD356]">Giải Pháp Toàn Diện </h1>
        <h1 className="text-[#142548]">cho sự phát triển của trẻ </h1>
      </div>
      <div className="flex justify-center">
        <div className=" flex w-full h-[650px] pv:max-md:h-[380px] md:max-lg:h-[500px] dv:max-dh:h-[750px] pv:max-md:w-[75%] dhmin:max-dh:w-[90%] justify-center items-center">
          <Swiper
            className="w-[80%] h-full"
            slidesPerView={slidesPerView}
            spaceBetween={40}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Navigation]}
          >
            {islandsData.map((subject, index) => (
              <SwiperSlide
                key={index}
                className="flex justify-center items-center"
              >
                <IslandCardAbout
                  imgPath={subject.imgPath}
                  islandName={subject.islandName}
                  description={subject.description}
                  listSubject={subject.listSubject}
                  colorBgSubject={subject.bgColorSubject}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="relative">
        {/* Phần tiêu đề */}
        <div className="flex w-full justify-center">
          <div className="font-[800] uppercase text-[80px] pv:max-md:text-[30px] md:max-lg:text-[50px] text-center absolute top-[20%] transform -translate-y-[50%]">
            <h1 className="text-[#65AFFF]">tính năng hiện đại</h1>
            <h1 className="text-[#142548]">học vui mỗi ngày</h1>
          </div>
        </div>

        {/* Nút Play */}
        <div className="flex justify-center">
          <div
            className="absolute bottom-[33%] dv:max-dh:bottom-[35%] h-[10vw] w-[10vw] max-w-[100px] max-h-[100px] flex items-center justify-center cursor-pointer rounded-full"
            onClick={() => {
              window.open("https://www.youtube.com/watch?v=FkKfPAg2oBo");
            }}
          >
            <img src="/logo/play_icon.png" alt="Play Icon" />
          </div>
        </div>

        {/* Hình nền */}
        <img
          src="/about_whykids/tinh_nang_hien_dai.png"
          className="w-full object-cover"
          alt="Tính năng hiện đại"
        />
      </div>

      <div
        className="h-auto py-28 pv:max-md:py-8"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "repeat",
        }}
      >
        <h1 className="uppercase text-[70px] pv:max-md:text-[30px] md:max-lg:text-[50px] font-[800] text-center text-[#142548]">
          vũ trụ <span className="text-[#F56179]">Why Kids</span>
        </h1>
        <div className="flex flex-col justify-center items-center w-full gap-28 pv:max-md:gap-10 ">
          {figureCardData.map((imagePath, index) => (
            <img
              key={index}
              className="w-[1384px] pv:max-lg:w-[90%] object-contain"
              src={imagePath}
              alt={`Image ${index + 1}`}
            />
          ))}
        </div>
      </div>
      <img
        className="w-full"
        src="/about_whykids/daigiadinh_Why Kids.png"
        alt=""
      />
      <Footer />
    </div>
  );
};

export default AboutWhyKids;
