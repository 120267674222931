import axios from "axios";

const axiosConfigNoAuth = axios.create({
  baseURL: 'https://why-kid-affiliate-dot-why-kids-educations-v2.as.r.appspot.com',
  headers: {
    "Content-type": "application/json",
  },
});
export const checkRefCode = async (refCode: string) => {
  const response = await axiosConfigNoAuth({
    method: "GET",
    url: `/util/referal-check?id=${refCode}`,
  });
  return response.data;
};
//https://why-kid-affiliate-dot-why-kids-educations-v2.as.r.appspot.com/util/referal-check?id=k00017
//https://why-kid-affiliate-dot-why-kids-educations-v2.as.r.appspot.com